<template>
  <div class="form-group">
    <div :class="{ checked: modelValue }"
      class="checkbox cursor-pointer"
    >
      <input v-taptic="'selection'"
        type="checkbox"
        :name="name"
        :checked="modelValue"
        :data-test-id="dataTestId"
        @change="() => $emit('update:modelValue', !modelValue)"
      >
      <slot><!--Checkbox label text--></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CheckBox',

  props: {
    name: {
      type: String,
      default: `checkbox_${Math.floor(Math.random() * 100 + 1)}`,
    },

    modelValue: { type: Boolean, required: true },

    dataTestId: { type: String, default: null },
  },

  emits: ['update:modelValue']
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/mixins/styling.scss';
@import '@/assets/css/mixins/interactions.scss';

.checkbox {
  display: flex;
  align-items: center;
  transition: color 110ms ease-out;

  [type='checkbox'] {
    width: 1.25rem;
    height: 1.25rem;
    border: 1px solid var(--color-neutral-tertiary);
    border-radius: 0.25rem;
    margin-right: 0.75rem;
    display: flex;
    align-items: center;
    justify-content: center;
    -webkit-appearance: none;
    transition: border 150ms ease-out;

    &:before {
      content: url('/assets/img/check.svg');
      display: block;
      position: relative;
      width: 1rem;
      top: 0.125rem;
    }

    &:checked {
      @include gradient-bg;
      border: 1px solid var(--color-brand-primary);

      &:before {
        opacity: 1;
        transition: opacity 110ms ease-out;
      }
    }
  }

  @include hover {
    &:hover {
      cursor: pointer;

      [type='checkbox'] {
        border: 1px solid var(--color-brand-primary);
        transition: border 150ms ease-out;
      }
    }
  }
}

.form-group {
  display: flex;
  align-items: center;
}
</style>
